import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialLinks from "../components/socialLinks"

const NotFoundPage = ({data}) => {
  const {pageData, svgFiles} = data;
  const links = [...pageData.siteMetadata.dev.links, ...pageData.siteMetadata.music.links];

  return (
    <Layout headerData={pageData.siteMetadata.pages.home}>
      <SEO title="404: Not found" />
      <SocialLinks svgFiles={svgFiles} links={links}></SocialLinks>
      <div style={{
        textAlign: `center`,
        marginTop: `100px`
      }}>
        <h1>Oh no! Page not found. <span className="emoji" role="img" aria-label="sad emoji">😢</span></h1>
        <p>Contact <a href="mailto:rahul@whadapp.com">Rahul</a> to figure out what's wrong</p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NotFoundPageData {
    pageData: site {
      siteMetadata {
        pages {
          home {
            title
            name
            description
          }
        }
        music {
          links {
            name
            url
            filename
            tooltip
            icon
          }
        }
        dev {
          links {
            name
            url
            filename
            tooltip
            icon
          }
        }
      }
    }
    svgFiles: allFile(filter: { extension: { eq: "svg" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }  
  }
`
export default NotFoundPage
